import "./App.css";
import { Routes, Route, useLocation, Navigate, Outlet, Link } from "react-router-dom";
import HomePage from "./Components/HomePage";
import Apicalling from "./Components/Apicalling"
import TableData from "./Components/TableData";
import SliderData from "./Components/SliderData";
import TableData2 from "./Components/TableData2";
function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<><Outlet></Outlet>
        <Apicalling />
          {/* <SliderData /> <TableData2 /> */}
          
          </>}>
        
          <Route
            path="home"
            element={<HomePage></HomePage>}
          />
          <Route path="slide" element={<SliderData/>} />
          <Route path="tabledata" element={<TableData />} />
          <Route path="table" element={<TableData2/>} />
        </Route>
      </Routes>
    </div>
  );
}
export default App;