import React from "react";
import "./table1.css";
import { useSelector } from "react-redux";
import moment from "moment";

const TableData2 = () => {
  const data = useSelector((state) => state.MetalDataReducer.data);
  console.log(data);
  return (
    <div>
     
      <span className="Updatedtime">
        Updated On: {moment(data[0]?.updated).format("yyyy-MM-DD hh:mm:ss A")}
      </span>

    
      <table className="table22"  >
        {/* <caption>Statement Summary</caption> */}
        <thead>
          <tr>
            <th scope="col">Metal Type</th>
            <th scope="col">Sale Rate per gm</th>
            <th scope="col">Purchase Rate per gm</th>
            {/* <th scope="col">Period</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return (
              <tr>
                <td data-label="Metal Type">
                  {/* // product name is silver 0 then silver article else if silver 100 then silver bar else as it is */}
                  {item.product_name === "Silver 0" ? (
                    "Silver Article"
                  ) : item.product_name === "silver 100" ? (
                    "Silver Bar"
                  ) : (
                    <>{ item.product_name}T</>
                  )}
                </td>
                <td data-label="Sale Rate per gm">
                 
                    { Number(item.sale_amount).toFixed(2)}
                </td>

                 {/* {item.sale_amount % 1 === 0
                    ? `${item.sale_amount}.0`
                    : item.sale_amount} */}

                {/* <td data-label="Sale Rate per gm">{Math.round(item.sale_amount)}</td> */}

                <td data-label="Purchase Rate per gm">
                  {Number(item.purchase_amount).toFixed(2)}
                  {/* {item.purchase_amount} */}
                  {/* {item.purchase_amount % 1 === 0
                    ? `${item.purchase_amount}.0`
                    : item.purchase_amount} */}
                </td>
                {/* <td data-label="Period">03/01/2016 - 03/31/2016</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableData2;
