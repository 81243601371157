import React from 'react'
import SliderData from './SliderData';
import TableData from './TableData';
import TableData2 from './TableData2';
function HomePage() {
  return (
    <>
    <div style={{height:"80vh"}}>
    <SliderData/>
    
    <TableData2 />
    </div>
    </>
  )
}

export default HomePage