
import React from 'react'
import { useSelector } from "react-redux";
import { addZeroes } from "../Components/CommonFunction";
import Marquee from "react-fast-marquee"

function SliderData() {
  const data = useSelector(state => state.MetalDataReducer.data)
  return (
    <>
      <div className="marquee">
        {/* <img src="kra.png" alt="" style={{ marginTop: "5px", marginLeft: "10px", height: "95px" }} /> */}
        <div className="tab1">
            <span className="text">
              Sale Rate
            </span>
            <span className="text1">
              Purchase Rate
            </span>
          </div>
        <Marquee 
        className="marq" 
        gradient={null} speed={20}
        >
       
          {data?.map((products) => {
            return (
              <div className="tab">
                <span className="text">
                  {products.product_name === "Silver 0" ? "Silver Article" : products.product_name === "silver 100" ? "Silver Bar" : <>{products.product_name}T</>} : {products.sale_amount == null ? "-" : <>₹ {addZeroes(products.sale_amount)}</>}
                </span>
                <span className="text1">
                  {products.product_name === "Silver 0" ? "Silver Article" : products.product_name === "silver 100" ? "Silver Bar" : <>{products.product_name}T</>}  : {products.purchase_amount == null ? "-" : <>₹ {addZeroes(products.purchase_amount)}</>}
                </span>
              </div>
            );
          })}
        </Marquee>
       </div>
    </>
  )
}

export default SliderData