
import React, { useEffect, useState } from "react";
import { Table, Spin } from "antd";
import { useSelector } from "react-redux";
import { capitalizeWords, addZeroes } from "../Components/CommonFunction";
import moment from "moment";

const TableData = () => {
  const data = useSelector((state) => state.MetalDataReducer.data);
  
  console.log(data);
  const columns = [
    {
      children: [
        // {
        //   title: "Metal",
        //   dataIndex: "product_name",
        //   key: "product_name",
        //   align: "center",
        //   // render: (text) => <>{capitalizeWords(text?.split(" ")[0])}</>,
        // },
        {
          title: "Metal Type",
          dataIndex: "product_name",
          key: "product_name",
          align: "center",
          
          render: (text, record) => (
            <>
              {record.product_name === "Silver 0" ? (
                "Silver Article"
              ) : record.product_name === "silver 100" ? (
                " Silver Bar"
              ) : (
                <>{text}T</>
              )}
            </>
          ),
        },
        {
          title: "Rate",
          align: "center",
          children: [
            {
              title: "Sale Per gram",
              dataIndex: "sale_amount",
              key: "sale_amount",
              align: "center",
              render: (text, record) => (
                <>
                  {text == null ? (
                    "-"
                  ) : (
                    <>
                      {"₹"}
                      {addZeroes(text)}
                    </>
                  )}
                </>
              ),
            },
            {
              title: "Purchase Per gram",
              dataIndex: "purchase_amount",
              key: "purchase_amount",
              align: "center",
              render: (text, record) => (
                <>
                  {text == null ? (
                    "-"
                  ) : (
                    <>
                      {"₹"}
                      {addZeroes(text)}
                    </>
                  )}
                </>
              ),
            },
          ],
        },
      ],
    },
  ];
  return (
    <div>
      <div
        style={{
          float: "right",
          marginRight: "20px",
          marginBottom: "5px",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        Updated On: {moment(data[0]?.updated).format("yyyy-MM-DD hh:mm:ss A")}
        {console.warn(data[0])}
      </div>

      {data == "" ? (
        <Spin size="large"></Spin>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          sorter={true}
          pagination={false}
          bordered
        />
      )}
    </div>
  );
};
export default TableData;